import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "dro-container dro-container-pa" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col col-12 reset-form-container" }
const _hoisted_4 = { class: "dx-button-text" }
const _hoisted_5 = { key: 1 }

import DxForm, {
  DxItem,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxRequiredRule,
  DxEmailRule,
} from "devextreme-vue/form";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import notify from "devextreme/ui/notify";
import { ref, reactive, watch } from "vue";
import { useRouter } from "vue-router";

import { resetPassword } from "@/store/api/auth";
import { IResetPasswordRequest } from "@/core/interface/IResetPasswordRequest";

const notificationText =
  "Na zadaný email jsme odeslali odkaz na resetování hesla.";


export default /*@__PURE__*/_defineComponent({
  __name: 'dro-app-loginresetpwd',
  props: {
  resetPasswordPopupContentShown: Boolean,
},
  setup(__props) {

const props = __props;

const router = useRouter();

const loading = ref(false);
const formData = reactive({
  email: "",
});
const resetPasswordFormRef = ref(null);

watch(
  () => props.resetPasswordPopupContentShown,
  () => {
    if (
      props.resetPasswordPopupContentShown === true &&
      resetPasswordFormRef.value
    ) {
      const resetPasswordFormInstance = (resetPasswordFormRef.value as DxForm)
        .instance;
      if (resetPasswordFormInstance) {
        const emailInstance = resetPasswordFormInstance.getEditor("email");
        if (emailInstance) {
          emailInstance.focus();
        }
      }
    }
  }
);

async function onSubmit() {
  loading.value = true;

  let resetPasswordRequest: IResetPasswordRequest = {
    email: formData.email,
  };
  const result = await resetPassword(resetPasswordRequest);

  loading.value = false;

  if (result.isOk) {
    router.push("/");
    notify(notificationText, "success", 2500);
  } else {
    notify(result.message, "error", 4000);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "row" }, [
      _createElementVNode("div", { class: "col col-12" }, [
        _createElementVNode("p", null, "Zadejte e-mailovou adresu, ke které jste zapomněli své heslo.")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          class: "reset-password-form",
          onSubmit: _withModifiers(onSubmit, ["prevent"])
        }, [
          _createVNode(_unref(DxForm), {
            "form-data": formData,
            ref_key: "resetPasswordFormRef",
            ref: resetPasswordFormRef,
            disabled: loading.value
          }, {
            resetTemplate: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_4, [
                  (loading.value)
                    ? (_openBlock(), _createBlock(_unref(DxLoadIndicator), {
                        key: 0,
                        width: "24px",
                        height: "24px",
                        visible: true
                      }))
                    : _createCommentVNode("", true),
                  (!loading.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Resetovat heslo"))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_unref(DxItem), {
                "data-field": "email",
                "editor-type": "dxTextBox",
                "editor-options": {
                stylingMode: 'filled',
                placeholder: 'Email',
                mode: 'email',
              }
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DxRequiredRule), { message: "Email je povinný" }),
                  _createVNode(_unref(DxEmailRule), { message: "Email je neplatný" }),
                  _createVNode(_unref(DxLabel), { visible: false })
                ]),
                _: 1
              }),
              _createVNode(_unref(DxButtonItem), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(DxButtonOptions), {
                    "element-attr": { class: 'submit-button' },
                    width: "100%",
                    type: "default",
                    template: "resetTemplate",
                    "use-submit-behavior": true
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["form-data", "disabled"])
        ], 32)
      ])
    ])
  ]))
}
}

})