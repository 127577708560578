import "devextreme/dist/css/dx.fluent.blue.light.compact.css";
import { createApp } from "vue";
import App from "@/App.vue";
import router from "./router";
import store from "./store";
//import axios from "axios";
import appInfo from "./app-info";
import config from "devextreme/core/config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { GetAppConfig } from "@/functions";
import { licenseKey } from "./devextreme-license";

config({ licenseKey } as any);

const app = createApp(App);
app.config.globalProperties.$appInfo = appInfo;

GetAppConfig().then((resultAppConfig) => {
  if (resultAppConfig.serverServiceUrl)
    store.commit("setCfgServiceUrl", resultAppConfig.serverServiceUrl);
  if (resultAppConfig.devServerServiceUrl)
    store.commit("setDevCfgServiceUrl", resultAppConfig.devServerServiceUrl);

  app.use(router);
  app.use(store);
  app.mount("#app");
});
