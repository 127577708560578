<template>
  <div class="dro-container dro-container-pa">
    <div class="row">
      <div class="col col-12">
        <p>Zadejte e-mailovou adresu, ke které jste zapomněli své heslo.</p>
      </div>
    </div>
    <div class="row">
      <div class="col col-12 reset-form-container">
        <form class="reset-password-form" @submit.prevent="onSubmit">
          <dx-form
            :form-data="formData"
            ref="resetPasswordFormRef"
            :disabled="loading"
          >
            <dx-item
              data-field="email"
              editor-type="dxTextBox"
              :editor-options="{
                stylingMode: 'filled',
                placeholder: 'Email',
                mode: 'email',
              }"
            >
              <dx-required-rule message="Email je povinný" />
              <dx-email-rule message="Email je neplatný" />
              <dx-label :visible="false" />
            </dx-item>
            <dx-button-item>
              <dx-button-options
                :element-attr="{ class: 'submit-button' }"
                width="100%"
                type="default"
                template="resetTemplate"
                :use-submit-behavior="true"
              >
              </dx-button-options>
            </dx-button-item>
            <template #resetTemplate>
              <div>
                <span class="dx-button-text">
                  <dx-load-indicator
                    v-if="loading"
                    width="24px"
                    height="24px"
                    :visible="true"
                  />
                  <span v-if="!loading">Resetovat heslo</span>
                </span>
              </div>
            </template>
          </dx-form>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DxForm, {
  DxItem,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxRequiredRule,
  DxEmailRule,
} from "devextreme-vue/form";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import notify from "devextreme/ui/notify";
import { ref, reactive, watch, defineProps } from "vue";
import { useRouter } from "vue-router";

import { resetPassword } from "@/store/api/auth";
import { IResetPasswordRequest } from "@/core/interface/IResetPasswordRequest";

const props = defineProps({
  resetPasswordPopupContentShown: Boolean,
});

const notificationText =
  "Na zadaný email jsme odeslali odkaz na resetování hesla.";

const router = useRouter();

const loading = ref(false);
const formData = reactive({
  email: "",
});
const resetPasswordFormRef = ref(null);

watch(
  () => props.resetPasswordPopupContentShown,
  () => {
    if (
      props.resetPasswordPopupContentShown === true &&
      resetPasswordFormRef.value
    ) {
      const resetPasswordFormInstance = (resetPasswordFormRef.value as DxForm)
        .instance;
      if (resetPasswordFormInstance) {
        const emailInstance = resetPasswordFormInstance.getEditor("email");
        if (emailInstance) {
          emailInstance.focus();
        }
      }
    }
  }
);

async function onSubmit() {
  loading.value = true;

  let resetPasswordRequest: IResetPasswordRequest = {
    email: formData.email,
  };
  const result = await resetPassword(resetPasswordRequest);

  loading.value = false;

  if (result.isOk) {
    router.push("/");
    notify(notificationText, "success", 2500);
  } else {
    notify(result.message, "error", 4000);
  }
}
</script>

<style lang="scss">
@import "@/styles/dro-styles.scss";

.reset-form-container {
  max-width: 400px;
  margin: 0 auto;
}

.reset-password-form {
  .submit-button {
    margin-top: 10px;
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }
}
</style>
