import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "dro-login-tab-item" }
const _hoisted_2 = { class: "dx-button-text" }
const _hoisted_3 = { key: 1 }

import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxEmailRule,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
} from "devextreme-vue/form";
import DxTabPanel from "devextreme-vue/tab-panel";
import DxCheckBox from "devextreme-vue/check-box";
import notify from "devextreme/ui/notify";

import { login } from "@/store/api/auth";
import { ILoginRequest } from "@/core/interface/ILoginRequest";

import { ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";

import { SetElemToWindowName } from "@/functions";


export default /*@__PURE__*/_defineComponent({
  __name: 'dro-login',
  props: {
  loginUserPopupContentShown: Boolean,
},
  emits: ["resetPasswordClick"],
  setup(__props, { emit: __emit }) {

const props = __props;

const store = useStore();

const loading = ref(false);
const formData = reactive({
  email: "",
  password: "",
});
const loginFormRef = ref(null);

const tabDataSource = [
  {
    icon: "description",
    title: "Přihlášení",
    name: "login",
  },
  {
    icon: "description",
    title: "Registrace",
    name: "register",
  },
];

//const useDevServer = ref(false);
//useDevServer.value = store.getters["getUseDevCfgService"];

const useDevServer = computed({
  get() {
    return store.getters["getUseDevCfgService"];
  },
  set(newValue) {
    store.commit("setUseDevCfgService", newValue);
  },
});

const devServiceUrlEnabled = computed(() => {
  return store.getters["getDevCfgServiceUrl"] ? true : false;
});

const emit = __emit;

watch(
  () => props.loginUserPopupContentShown,
  () => {
    if (props.loginUserPopupContentShown === true && loginFormRef.value) {
      const loginFormInstance = (loginFormRef.value as DxForm).instance;
      if (loginFormInstance) {
        const emailInstance = loginFormInstance.getEditor("email");
        if (emailInstance) {
          emailInstance.focus();
        }
      }
    }
  }
);

function resetPasswordClick() {
  emit("resetPasswordClick");
}

async function onSubmit() {
  loading.value = true;

  store.commit("setUseDevCfgService", useDevServer.value);

  let loginRequest: ILoginRequest = {
    userId: formData.email,
    pwd: formData.password,
  };
  const result = await login(loginRequest);

  loading.value = false;

  if (!result) {
    notify("Unknown error (empty login response)", "error", 5000);
  } else if (!result.isOk) {
    notify(result.message, "error", 5000);
  } else if (!result.data) {
    notify("No data in login response", "error", 5000);
  } else if (!result.data.accessToken) {
    notify("No accessToken in login response", "error", 5000);
  } else if (!result.data.redirectTo) {
    notify("No redirectTo in login response", "error", 5000);
  } else if (!result.data.urlBackend) {
    notify("No urlBackend in login response", "error", 5000);
  } else {
    //let urlRedirect = null;
    let urlBackend = null;
    let accessToken = null;

    try {
      urlBackend = new URL(result.data.urlBackend);
    } catch (_) {
      notify("Bad URL in urlBackend", "error", 5000);
      return;
    }

    accessToken = result.data.accessToken;
    if (accessToken.length < 40) {
      notify("Bad accessToken in login response", "error", 5000);
      return;
    }

    SetElemToWindowName("ACCESS-TOKEN", accessToken);
    SetElemToWindowName("URL-BACKEND", urlBackend.toString());

    // Redirect na WM
    if (process.env.NODE_ENV === "development") {
      window.location.href = "http://localhost:8082/";
    } else if (result.data && result.data.redirectTo) {
      window.location.href = result.data.redirectTo;
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DxTabPanel), {
    width: "100%",
    "item-template": "tabPanelItem",
    "animation-enabled": true,
    "swipe-enabled": true,
    "data-source": tabDataSource,
    "tabs-position": "top",
    "icon-position": "start",
    "styling-mode": "primary",
    class: "dro-login-tab"
  }, {
    tabPanelItem: _withCtx(({ data }) => [
      _createElementVNode("div", _hoisted_1, [
        (data.name === 'login')
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              class: "dro-login-form",
              onSubmit: _withModifiers(onSubmit, ["prevent"])
            }, [
              _createVNode(_unref(DxForm), {
                "form-data": formData,
                ref_key: "loginFormRef",
                ref: loginFormRef,
                disabled: loading.value
              }, {
                signInTemplate: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_2, [
                      (loading.value)
                        ? (_openBlock(), _createBlock(_unref(DxLoadIndicator), {
                            key: 0,
                            width: "24px",
                            height: "24px",
                            visible: true
                          }))
                        : _createCommentVNode("", true),
                      (!loading.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Přihlásit"))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_unref(DxItem), {
                    "data-field": "email",
                    "editor-type": "dxTextBox",
                    "editor-options": {
                stylingMode: 'filled',
                placeholder: 'Email',
                mode: 'email',
                autoFocus: true,
              }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DxRequiredRule), { message: "Email je povinný" }),
                      _createVNode(_unref(DxEmailRule), { message: "Email není platný" }),
                      _createVNode(_unref(DxLabel), { visible: false })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(DxItem), {
                    "data-field": "password",
                    "editor-type": "dxTextBox",
                    "editor-options": {
                stylingMode: 'filled',
                placeholder: 'Heslo',
                mode: 'password',
              }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DxRequiredRule), { message: "Heslo je povinné" }),
                      _createVNode(_unref(DxLabel), { visible: false })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(DxButtonItem), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DxButtonOptions), {
                        width: "100%",
                        height: "32px",
                        type: "default",
                        template: "signInTemplate",
                        "use-submit-behavior": true
                      })
                    ]),
                    _: 1
                  }),
                  (devServiceUrlEnabled.value)
                    ? (_openBlock(), _createBlock(_unref(DxItem), { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(DxCheckBox), {
                            modelValue: useDevServer.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((useDevServer).value = $event)),
                            text: "Použít dev server"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(DxItem), null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "dro-link",
                        onClick: resetPasswordClick
                      }, " Zapomněli jste heslo? ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["form-data", "disabled"])
            ], 32))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})